<template>
  <div>
    <navigation
      :sections="sections"
      :firstpage="firstPage"
      @menuopened="menuchanged"
    />

    <router-view
      :homepage="home"
      :firstpage="firstPage"
      :sections="sections"
      v-slot="{ Component }"
    >
      <transition :name="transName" mode="out-in">
        <component :key="$route.params.section" :is="Component" />
      </transition>
    </router-view>
    <apropos :apropos="apropos" :menuopen="menuopen" />
  </div>
</template>

<script>
var data = JSON.parse(
  document.querySelector("script[type='application/json']").innerHTML
);
// console.log(data)
import navigation from "@/views/Navigation.vue";
import apropos from "@/views/APropos.vue";
export default {
  data() {
    return { ...data, transName: "fade", menuopen: false };
  },
  components: { navigation, apropos },
  watch: {
    $route: {
      handler: function (to, from) {
        var title = "Sylvie Mermoud";
        if (to.name == "About") {
          title += " - A Propos";
        } else if (to.name == "Section" || to.name == "SubSection") {
          title += " - " + this.decodeHtml(this.getCurrentProject.title);
        }
        console.log(from);
        document.title = title || "Your Website";

        // eslint-disable-line no-unused-vars
        if (to.name) {
          if (to.name == "About") {
            this.transName = "slowfade";
          } else if (from.name == "About") {
            this.transName = "slowfade";
          } else {
            if (from) {
              console.log(from.name)
              if (from.name == "SubSection" && to.name == "SubSection") {
                console.log("working")
                this.transName = "instantout";
              } else {
                this.transName = "fade";
              }
            } else {
              this.transName = "fade";
            }
          }
        }
      },
      immediate: true,
    },
    
  },
  mounted() {
    if (this.firstPage) {
      setTimeout(
        function () {
          this.firstPage = false;
        }.bind(this),
        3000
      );
    }
  },
  methods: {
    menuchanged(yes) {
      this.menuopen = yes;
      console.log(this.menuopen);
    },
    decodeHtml(html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },
  },
};
</script>
<style lang="scss">
html {
  overscroll-behavior-x: contain;
}
html,
body {
  margin: 0px;
  padding: 0px;
  // overflow: hidden;
  width: 100%;
  height: 100%;
  font-family: $font1;
  font-size: 16px;
  // font-weight: 500;
  background-color: white;
  overscroll-behavior-y: none;
  overflow-x: hidden;
}
body {
}
b {
  font-weight: 700;
}

.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: opacity 0s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.slowfade-enter-active,
.slowfade-leave-active {
  opacity: 1;
  transition: opacity 0.5s ease;
}
.slowfade-enter-from,
.slowfade-leave-to {
  opacity: 0;
}
.instantout-enter-active,
.instantout-leave-active {
  opacity: 0;
  transition: opacity 0s ease;
}
.instantout-enter-from,
.instantout-leave-to {
  opacity: 0;
}

.invisible {
  opacity: 0 !important;

  transition: opacity 500ms;
  // display: none;
}
p:first-of-type {
  margin-top: 0px;
  padding-top: 0px;
}
p:last-of-type {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
@media screen and (min-width: $breakpoint) {
  html,
  body {
    font-size: 18px;
    overflow-y: hidden;
    position: fixed;
  }

  .fade-enter-active,
  .fade-leave-active {
    opacity: 1;
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .instantout-enter-active,
.instantout-leave-active {
  opacity: 1;
  transition: opacity 0.5s ease;
}
.instantout-enter-from,
.instantout-leave-to {
  opacity: 0;
}
}
</style>
