<template>
  <div>
    <div id="aproposcontainer" :class="{'menuopen': menuopen}">
      <div id="bioContainer">
        Sylvie Mermoud
        <div id="bio" v-html="apropos.bio"></div>
        <div class="listContainer">
          <span class="sectionTitle">Expositions personnelles ou en duo</span>
          <eventlist
            :events="apropos.expos_personelles"
            noyear="false"
            class="last"
          />
        </div>
        <div class="listContainer">
          <span class="sectionTitle">Expositions collectives</span>
          <eventlist
            :events="apropos.expos_collectives"
            noyear="false"
            class="last"
          />
        </div>
        <div class="listContainer">
          <span class="sectionTitle">Acquisitions publiques</span>
          <div id="acquisitions" v-html="apropos.acquisitions_publiques"></div>
        </div>
        <div class="listContainer">
          <span class="sectionTitle">Catalogues et éditions</span>
          <div id="catalogues" v-html="apropos.catalogues_editions"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import eventlist from "@/views/EventList";
export default {
  props: ["firstpage", "apropos", 'menuopen'],
  data() {
    return {
      prevRoute: null,
      goingback: false,
    };
  },
  components: { eventlist },
  watch: {
    $route: {
      // eslint-disable-next-line no-unused-vars
      handler: function (to, from) {
        if (to.name) {
          if (to.name == "About") {
            document.getElementById("aproposcontainer").classList.add("active");
          } else {
            document
              .getElementById("aproposcontainer")
              .classList.remove("active");
          }
        }

        // console.log(from)
        if (from) {
          if (from.name) {
            this.prevRoute = from;
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    console.log(this.menuopen)
  },
  methods: {
    back() {
      if (this.prevRoute) {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: "Home" });
      }
      this.goingback = true;
    },
  },
};
</script>
<style lang="scss" scoped>
#aproposcontainer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 400;
  box-sizing: border-box;
  padding: 10px;
  transition: transform 500ms, opacity;
  transform: translateX(100%);
}
#aproposcontainer.menuopen {
  opacity: 0;
  transition: transform 500ms 500ms, opacity 0ms 500ms;
}
#aproposLinkDesktop {
  display: none;
}
#aproposcontainer.active {
  transform: translateX(0);
  transition: transform 500ms;
  #bioContainer {
    opacity: 1;
  }
  #closeAPropos {
    opacity: 1;
  }
}
#aproposLink {
  position: absolute;
  left: 50%;
  top: 15px;
  transform: translateX(-50%);
  background-color: white;
  z-index: 10;
}
#closeAPropos {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 10;
  opacity: 0;
  transition: opacity 500ms;
}
#links {
  transition: opacity 500ms;
  display: flex;
  position: absolute;
  top: 0px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  z-index: 300;
  left: 0px;
  pointer-events: none;
  span {
    flex-grow: 2;
    a {
      color: black;
      text-decoration: none;
      pointer-events: all;
    }
  }
  span:last-of-type {
    text-align: right;
  }
}

#bioContainer {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  left: 0px;
  padding-top: 33px;
  overflow-y: auto;
  color: $grey;
  line-height: 20px;

  background-color: rgb(255, 255, 255);
  opacity: 0;
  transition: opacity 500ms;
  #bio {
    margin-bottom: 0px
  }
  .sectionTitle {
    text-align: center;
    display: block;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .listContainer {
    // padding-top: 20px;
    color: $grey;
  }
}

#acquisitions, #catalogues {
  line-height: 25px;
  font-size: 85%;
}
#catalogues {
  margin-bottom: 40px;
}

@media screen and (min-width: $breakpoint) {
  #aproposLinkDesktop {
    position: fixed;
    top: $padding;
    right: $padding;
    display: block;
    z-index: 1000;
    font-size: 12px;
    a {
      text-decoration: none;
      color: grey;
      font-weight: bold;
    }
    a:hover {
      font-weight: normal;
    }
  }
  #closeAPropos {
    display: none;
  }
  #aproposcontainer {
    transform: translateX(100%);
    width: 50%;
    left: 50%;

    #bioContainer {
      opacity: 1;
      transition: opacity 200ms;
      background-color: white;
      padding-top: 28px;
    }
    #bio {
      padding-top: 20px;
    }
    #links {
      transition: none;
    }
  }
  #aproposLink {
    display: none;
  }
  #aproposcontainer.active {
    background-color: unset;
    transform: translateX(0);
    #bioContainer {
      opacity: 1;
      overflow-y: auto;
    }
    #links {
      transition: background-color 50ms 500ms;
    }
  }
}
</style>