<template>
  <div id="navigationContainer">
    <div id="topContainer">
      <ul
        id="sectionLinks"
        :class="[
          !firstpage ? '' : 'invisible',
          linksvisible ? '' : 'hidden',
          menuOpen ? 'open' : '',
        ]"
      >
        <template v-for="(section, i) in sections">
          <li :key="i" v-if="section.type == 'section'" class="firstLevel">
            <router-link
              :to="{
                name: 'Section',
                params: { section: getendURL(section) },
              }"
              class="button topSection"
              @click="closeMenu"
            >
              {{ section.title }}
            </router-link>
          </li>
          <li
            :key="i"
            v-if="section.type == 'parent'"
            class="firstLevel"
            @click="openCat"
          >
            <div
              :class="['button', isParentOfPage(section) ? 'currParent' : '']"
            >
              {{ section.title }}
            </div>
            <ul class="submenu">
              <li
                :key="j"
                v-for="(childSection, j) in section.children"
                class="sublink"
              >
                <router-link
                  :to="{
                    name: 'SubSection',
                    params: {
                      parent: getendURL(section),
                      section: getendURL(childSection),
                    },
                  }"
                  class="button"
                  @click.stop="closeMenu"
                >
                  {{ childSection.title }}
                </router-link>
              </li>
            </ul>
          </li>
        </template>
      </ul>
    </div>

    <transition name="fade">
      <div
        id="sectionTitle"
        v-if="getCurrentProject"
        :key="getCurrentProject.title"
        v-html="getCurrentProject.title"
      />
    </transition>
    <div :class="[!firstpage ? '' : 'invisible', 'mainLinks']">
      <div class="menulink" @click="handleMenu">MENU</div>
      <router-link :to="{ name: 'About' }" class="aproposlink" @click="back">
        À PROPOS
      </router-link>
      <router-link :to="{ name: 'Home' }" class="homelink">
        SYLVIE MERMOUD
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scrollfunc: "",
      currscroll: 0,
      linksvisible: false,
      menuclosed: true,
      ignorescroll: false,
      menuOpen: false,
      prevRoute: null,
    };
  },
  props: ["sections", "firstpage"],
  computed: {},
  methods: {
    // eslint-disable-next-line no-unused-vars
    isParentOfPage(section) {
      //
      if (this.getCurrentProject) {
        if (section == this.getCurrentProject.parent) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    handleMenu() {
      this.menuOpen = !this.menuOpen;
      this.$emit("menuopened", this.menuOpen)
    },
    closeMenu() {
      this.menuOpen = false;
      this.$emit("menuopened", this.menuOpen)
    },
    back() {
      if (this.$route.name == "About") {
        if (this.prevRoute) {
          this.$router.go(-1);
        } else {
          this.$router.push({ name: "Home" });
        }
      }
    },
  },

  watch: {
    $route: {
      // eslint-disable-next-line no-unused-vars
      handler: function (to, from) {
        if (to.name) {
          if (!(to.name == "Section" || to.name == "SubSection")) {
            this.linksvisible = true;
            this.menuclosed = false;
          } else {
            this.linksvisible = false;
            this.menuclosed = true;
          }
        }
        if (from) {
          if (from.name) {
            this.prevRoute = from;
          }
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
ul,
li {
  margin: 0px;
  padding: 0px;
}
a,
li {
  color: $grey;
  text-decoration: none;
}
#sectionLinks {
  width: 50vw;
  position: fixed;
  z-index: 200;
  list-style: none;
  text-align: left;
  top: $padding;
  left: $padding;
  display: flex;
  font-size: 12px;
  line-height: 1em;
  text-transform: uppercase;
}

li.firstLevel {
  position: relative;
  cursor: default;
  width: 27%;
  flex-basis: 27%;
  font-weight: bold;
}

ul.submenu {
  position: absolute;
  list-style: none;
  left: 0px;
  width: 100%;
  display: block;
  white-space: nowrap;
  font-weight: 400;
}
li.sublink a:hover {
  font-weight: 700;
}

a.topSection:hover {
  font-weight: normal;
}

#sectionTitle {
  display: block;
  position: absolute;
  left: 50%;
  top: 0px;
  font-family: "Times New Roman", Times, serif;
  font-size: 330%;
  letter-spacing: 0.9;
  transform: translateX(-50%);
  color: $grey;
  max-width: 250px;
  text-align: center;
  line-height: 0.8;
  z-index: 100;
}

a.aproposlink,
a.homelink,
.menulink {
  z-index: 1000;
  text-align: left;
  font-weight: bold;
  font-size: 12px;
  top: $padding;
  position: fixed;
  color: grey;
}
a.aproposlink:hover,
a.homelink:hover {
  font-weight: 400;
}
a.aproposlink {
  left: 73%;
}

a.homelink {
  left: 85%;
}
.menulink {
  display: none;
}

@media screen and (max-width: $breakpoint) {
  #sectionLinks {
    z-index: 1000000;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: white;
    text-align: center;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: space-around;
    line-height: unset;
    font-size: 20px;
    display: flex;
    opacity: 0;
    pointer-events: none;
    transition: opacity 500ms;
  }
  #sectionLinks.open {
    display: flex;
    opacity: 1;
    pointer-events: all;
    visibility: visible;
  }
  li.firstLevel {
    flex-basis: unset;
    flex-grow: 0;
    width: 100%;
  }
  ul.submenu {
    font-family: $font2;
    text-transform: none;
    font-size: 30px;
  }
  a.homelink {
    display: none;
  }
  .topSection {
    font-family: $font2;
    text-transform: none;
    font-size: 30px;
    font-weight: 400;
  }
  a:hover {
    // font: unset;
    font-weight: unset;
  }
  a.aproposlink:hover,
  a.homelink:hover {
    font-weight: 700;
  }
  li.sublink a:hover {
    font-weight: 400;
  }
  .menulink {
    display: block;
    top: $padding;
    left: $padding;
  }
  #sectionTitle {
    font-family: $font1;
    font-size: 12px;
    line-height: unset;
    letter-spacing: unset;
    font-weight: bold;
    text-transform: uppercase;
    top: 10px;
    position: fixed;
  }
  a.aproposlink {
    left: unset;
    right: 10px;
  }
}
</style>

<style lang="scss">
</style>