<template>
  <svg
    version="1.1"
    ref="svgBox"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    :viewBox="viewport"
    xml:space="preserve"
  >
    <polyline
      fill="#FFFFFF"
      stroke-miterlimit="10"
      :points="line"
      stroke-linecap="square" stroke-linejoin="square"
    />
  </svg>
</template>
<script>
export default {
  data() {
    return {
      width: 0,
      height: 0,
    };
  },
  computed: {
    viewport() {
      return "0 0 " + this.width + " " + this.height;
    },
    line() {
      var w = 40;

      return (
        "0.5,0.5 " +
        w +"," +(this.height - 0.5) +
        " " +
        (this.width - w) +"," +(this.height - 0.5) +
        " " +
        (this.width-0.5) +",0.5 "
      );
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.width = this.$refs.svgBox.clientWidth;
      this.height = this.$refs.svgBox.clientHeight;
    });
    // this.wi
  },
};
</script>

<style scoped lang="scss">
svg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -10;
}
polyline {
    stroke: $grey;
}


@media screen and (min-width: $breakpoint) {
    svg {
        display: none;
    }
}
</style>