<template>
  <div
    :key="getCurrentProject.title"
    class="sectionContainer"
    @wheel="moveContent"
  >
    <div
      id="contentMover"
      :style="{ transform: 'translateX(-' + contentX + 'px)' }"
    >
      <div
        v-for="(content, i) in getCurrentProject.content"
        :key="i + '-' + i"
        :class="[
          'contentContainer',
          i == currImage ? 'active' : '',
          content.type + 'Cont',
        ]"
      >
        <template v-if="content.type == 'image'">
          <imagehelper :image="content.images" class="lazyload" :alt="content.title+', '+content.technique" />

          <div class="descSizer">
            <div class="contentInfo">
              <template v-if="has(content.title)">
                <div class="worktitle">{{ content.title }}</div>
              </template>
              <template v-if="has(content.technique)">
                <div>{{ content.technique }}</div>
                <div class="desktopOnly">,&nbsp;</div>
              </template>
              <template v-if="has(content.dimensions)">
                <div>{{ content.dimensions }}</div>
              </template>
              <div class="date">{{ content.annee }}</div>
              <mobileline />
            </div>
          </div>
        </template>
        <template v-if="content.type == 'text'">
          <div class="textBorder">
            <div class="textContainer">
              {{ content.text }}
            </div>
          </div>
          <div class="descSizer">
            <div class="contentInfo">
              <div class="nameandnewspaper">
                <template v-if="has(content.line_1)">
                  <div class="articleName">{{ content.line_1 }}</div>,&nbsp;
                  <div>{{ content.line_3 }}</div>
                </template>
              </div>

              <template v-if="has(content.line_2)">
                <div>{{ content.line_2 }}</div>
              </template>
              <template v-if="has(content.line_4)">
                <div class="date">{{ content.line_4 }}</div>
              </template>
              <mobileline />
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import imagehelper from "./Image.vue";
import mobileline from "./MobileLine.vue";
import normalize from "normalize-wheel";
import lazyload from "lazyload";
export default {
  data() {
    return {
      currImage: 0,
      contentX: 0,
      contentWidth: 0,
      windowWidth: 0,
    };
  },
  components: {
    imagehelper,
    mobileline,
  },
  props: ["homepage", "sections"],
  mounted() {
    new lazyload();
    this.recalcContentWidth();
    window.addEventListener("resize", this.recalcContentWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.recalcContentWidth);
  },
  computed: {},
  methods: {
    has(el) {
      return el.trim() != "";
    },
    moveContent(e) {
      e.stopPropagation();
      this.contentX +=
        Math.abs(normalize(e).pixelY) > Math.abs(normalize(e).pixelX)
          ? normalize(e).pixelY
          : normalize(e).pixelX;
      this.adjustX();
    },
    adjustX() {
      if (this.windowWidth < 600) {
        this.contentX = 0;
      }
      this.contentX = Math.min(
        Math.max(this.contentX, 0),
        this.contentWidth - this.windowWidth
      );
    },
    recalcContentWidth() {
      this.contentWidth = 0;
      this.windowWidth = window.innerWidth;
      var els = document.querySelectorAll(".contentContainer");
      els.forEach((e) => {
        this.contentWidth += e.getBoundingClientRect().width;
      });
      this.adjustX();
    },
    resetLazyLoad() {
      setTimeout(
        function () {
          new lazyload();
        }.bind(this),
        20
      );
      setTimeout(
        function () {
          this.recalcContentWidth();
        }.bind(this),
        600
      );
    },
  },
  watch: {
    $route: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler: function (to, from) {
        if (to.params.section) {
          this.resetLazyLoad();
          this.currImage = 0;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.sectionContainer {
  position: absolute;
  top: 0px;
  display: block;
  background-color: white;
}
.contentContainer {
  width: 100%;
  // height: 100%;
  padding: 0px 10px;
  box-sizing: border-box;
}
#contentMover {
  margin: 33px 0px;
}

.imageCont img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}
.imageCont.active img {
  opacity: 1;
}

.textCont {
  width: 100%;
  // min-height: 50vh;
  box-sizing: border-box;
}
.textBorder {
  border: 3px solid $greylighter;
  color: $grey;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.textContainer {
  text-align: center;
  font-size: 20px;
  box-sizing: border-box;
  padding: 60px 20px;
}

.contentInfo {
  text-align: center;
  padding: 10px 0px;
  // border: 1px solid $grey;
  color: $grey;
  border-top: 0px;
  box-sizing: border-box;
  margin: 5px 0px;
  font-family: $font2;
  position: relative;
}

.worktitle {
  font-style: italic;
}

.desktopOnly {
  display: none;
}
@media screen and (min-width: $breakpoint) {
  .imgContainer {
    padding: 100px;
  }
  #contentMover {
    height: 100%;
    margin: 0px;
  }

  .sectionContainer {
    width: 100%;
    overflow: hidden;
    padding-top: 55px;
    height: 100%;
    box-sizing: border-box;
    white-space: nowrap;
    .contentContainer {
      height: 100%;
      width: unset;
      display: inline-block;
      padding: 0px 2px;
      vertical-align: top;
      padding-bottom: 60px;
    }
    .contentContainer:first-of-type {
      padding-left: 4px;
    }
    .contentContainer:last-of-type {
      padding-right: 4px;
    }
    .imageCont {
      img {
        height: 100%;
        flex-grow: 1;
        width: auto;
        display: block;
      }
    }

    .descSizer {
      display: flex;
    }
    .contentInfo {
      height: 100px;
      border: 0px;
      text-align: center;
      white-space: normal;
      padding: 0px;
      flex-grow: 1;
      width: 0;
      font-size: 75%;
      div {
        display: inline;
      }
      div.desktopOnly {
        display: inline;
      }
    }

    .textCont {
      .textBorder {
        border: 0px;
        padding: 0px 40px;
      }
      .textContainer {
        padding: 0px 3px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        font-size: 150%;
      }
      max-width: 65vh;
      .textContainer {
        white-space: normal;
      }
    }
  }
  .sectionContainer .contentInfo {
    .worktitle,
    .nameandnewspaper,
    .date {
      display: block;
    }
    .articleName {
      font-style: italic;
    }
  }
}
</style>