import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue"
import Section from "@/views/Section.vue"
import About from "@/views/About.vue"
const routes = [
    {
        path: (process.env.NODE_ENV !== 'production'?"/sylvie":"")+"/",
        name: "Home",
        component: Home,
        props: true
    },
    {
        name : "SubSection",
        component: Section,
        path: (process.env.NODE_ENV !== 'production'?"/sylvie":"")+"/s/:parent/:section/",
    },
    {
        name : "Section",
        component : Section,
        path: (process.env.NODE_ENV !== 'production'?"/sylvie":"")+"/s/:section/",
    },
    {
        name : "About",
        component : About,
        path: (process.env.NODE_ENV !== 'production'?"/sylvie":"")+"/a-propos/",
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({ left: 0, top: 0 })
            }, 500)
        });
    }
});




export default router;