export default {
  methods: {
    getendURL(p) {
        var split = p.url.split("/");
        var beforelast = split[split.length - 2];
        return beforelast;
      },
  },
  computed : {
    getCurrentProject() {
        var pID = this.$route.params.section;
        var currSection;
        
        for (var i = 0; i < this.sections.length; i++) {
          if (this.getendURL(this.sections[i]) == pID) {
            currSection = this.sections[i];
            currSection.parent = null;
            break;
          }
          if (this.sections[i].type == "parent") {
            for (var j = 0; j < this.sections[i].children.length; j++) {
              if (this.getendURL(this.sections[i].children[j]) == pID) {
                currSection = this.sections[i].children[j];
                currSection.parent = this.sections[i];
                break;
              }
            }
            if (currSection) break;
          }
        }
        return currSection;
      },
  }
}