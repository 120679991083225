<template>
  <div id="homeContainer" @mousemove="changeImage">
    <div
      v-for="(img, i) in homepage"
      :key="i"
      :class="[
        'imgContainer',
        img.isfullscreen ? 'fullscreen' : 'centered',
        i == currImage ? 'active' : '',
      ]"
    >
      <imagehelper :image="img.images" class="lazyload" alt="homepage image"/>
    </div>
    <div id="nameContainer" :class="[firstpage ? '' : 'invisible']">
      <div class="namePlacer">Sylvie Mermoud</div>
    </div>
  </div>
</template>

<script>
import imagehelper from "./Image.vue";
import lazyload from "lazyload";
export default {
  data() {
    return {
      currImage: 0,
      mouseInit: false,
      mousePos: {
        x: 0,
        y: 0,
      },
      defaultImageChange: 3000,
      totalDistance: 0,
    };
  },
  components: {
    imagehelper,
  },
  props: ["homepage", "firstpage"],
  mounted() {
    // eslint-disable-next-line no-unused-vars
    var lazy = new lazyload(); //es-lint
    setTimeout(this.checkMouseInit, this.defaultImageChange);
  },
  methods: {
    nav(forward) {
      this.currImage += (forward ? 1 : -1) + this.homepage.length;
      this.currImage %= this.homepage.length;
    },
    dist(x, y, pos) {
      return Math.sqrt(Math.pow(pos.x - x, 2) + Math.pow(pos.y - y, 2));
    },
    changeImage(e) {
      if (!this.mouseInit) {
        this.mouseInit = true;
        this.mousePos.x = e.clientX;
        this.mousePos.y = e.clientY;
      } else {
        this.totalDistance += this.dist(e.clientX, e.clientY, this.mousePos);
        this.mousePos.x = e.clientX;
        this.mousePos.y = e.clientY;
      }

      if (this.totalDistance > 100 * window.devicePixelRatio) {
        this.totalDistance = 0;
        this.currImage++;
        this.currImage %= this.homepage.length;
      }
    },
    checkMouseInit() {
      if (!this.mouseInit) {
        this.currImage++;
        this.currImage %= this.homepage.length;
        setTimeout(this.checkMouseInit, this.defaultImageChange);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#homeContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
}
#nameContainer {
  position: absolute;
  top: 0%;
  left: 0%;
  font-size: 40px;
  white-space: nowrap;
  color: white;
  text-align: center;
  opacity: 1;
  transition: opacity 500ms;
  background-color: grey;
  width: 100%;
  height: 100%;
  .namePlacer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: default;
  }
}

.imgContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  // display: none;
}
.imgContainer img {
  opacity: 0;
  transition: opacity 900ms;
}
.imgContainer.active img {
  opacity: 1;
}

.fullscreen img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.centered {
  padding: 100px 13vw;
  box-sizing: border-box;
}
.centered img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

@media screen and (min-width: $breakpoint) {
  #nameContainer {
    font-size: 100px;
  }
}
</style>
